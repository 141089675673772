<template>
  <div>
    <div class="row align-items-end">
      <div class="col-3">
        <label for="">Référence</label>
        <input
          type="text"
          :disabled="true"
          :value="user.employee_reference"
          class="form-control"
        />
      </div>
      <div class="col">
        <label for="">Nom Complet</label>
        <select
          v-model="user.employee_reference"
          name=""
          id=""
          class="form-select"
        >
          <option
            v-for="(employee, index) in employees"
            :key="index++"
            :value="employee.reference"
          >
            {{ employee.fullName }}
          </option>
        </select>
      </div>

      <div class="col-auto">
        <label for=""> </label>
        <button
          @click="$router.push({ name: 'employees-create' })"
          class="btn btn-secondary"
        >
          <i class="bi bi-plus-square"></i>
          Créer un employée
        </button>
      </div>
      <div class="col-3">
        <label for="">Rôle</label>
        <select
          name=""
          v-model="user.user_role_reference"
          id=""
          class="form-select"
        >
          <option
            v-for="(item, index) in user_roles"
            :key="index++"
            :value="item.reference"
          >
            {{ item.fullName }}
          </option>
        </select>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-3">
        <label for="">Nom d'utilisateur (username)</label>
        <input type="text" v-model="user.username" class="form-control" />
      </div>

      <div class="col-3">
        <label for="">Mot de passe</label>
        <input type="text" v-model="user.password" class="form-control" />
      </div>
    </div>

    <br />
    <div class="row mt-3 justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button class="btn btn-success" @click="save(user)">
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      user: {},
    };
  },
  computed: {
    ...mapGetters("user", {
      user_roles: "getUserRoles",
    }),
    ...mapGetters("employee", {
      employees: "getAll",
    }),
  },
  methods: {
    async save(data) {
      await this.$store.dispatch("user/store", data);
    },
  },
  beforeMount() {
    this.$store.dispatch("employee/getAll");
    this.$store.dispatch("user/getUserRoles");
    if (this.$route.query.employeeReference)
      this.$store.dispatch(
        "employee/show",
        this.$route.query.employeeReference
      );
  },
};
</script>
